import request from '@/utils/request'

// 导出任务列表
export function findDataExportTasks(params) {
  return request({
    url: `/data_export_tasks`,
    method: 'get',
    params: params
  })
}
