<template>
  <div>
    <div class="iotplt-table-operation-buttons">
      <h3 style="flex: auto;">注意：一个月之前的导出任务会被系统自动删除，请及时下载</h3>
      <a-space>
        <a-button
          type="primary"
          @click="fetchData"
        >刷新</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="downloadFile(record.id, record.file_name)" v-if="isCanDownload(record)">下载</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { findDataExportTasks } from '@/api/data_export_task'
import Pagination from '@/components/Pagination/index'
import { exportExcel } from '@/api/excel'

export default {
  name: 'DataExportTaskList',
  components: {
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '任务 ID',
          width: 100,
          dataIndex: 'id'
        },
        {
          title: '文件状态',
          width: 180,
          dataIndex: 'file_status'
        },
        {
          title: '导出时间',
          width: 150,
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    downloadFile(id, file_name) {
      exportExcel(id, file_name)
    },
    isCanDownload(record) {
      return record.status === 'true'
    },
    fetchData() {
      this.loading = true
      findDataExportTasks(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
